<template>
	<!-- 管理员列表 -->
	<div class="customerGroup">
		<div class="table">
			<div class="table-operation">
				<el-button type="warning" icon="el-icon-plus" @click.stop="openDialog(null)">新增</el-button>
				<el-button type="info" icon="el-icon-refresh-left" @click.stop="loading = true, getList()"></el-button>
			</div>
			<Table :data="tableData" :total="total" v-loading="loading"
				@size-change="pageNum = $event, loading = true, getList()"
				@current-change="page = $event, loading = true, getList()">
				<el-table-column label="用户名" prop="username" align="center"></el-table-column>
				<el-table-column label="登录账号" prop="account" align="center"></el-table-column>
				<el-table-column label="说明" prop="remark" align="center"></el-table-column>
				<el-table-column label="电脑端权限" prop="menus_text" align="center"></el-table-column>
				<el-table-column label="移动端权限" prop="app_menus_text" align="center"></el-table-column>
				<el-table-column label="创建时间" prop="created_at" align="center"></el-table-column>
				<el-table-column label="操作" align="center" width="160">
					<template slot-scope="scope">
						<el-button type="warning" size="mini"
							@click.stop="dialogVisible = true, dialogType = 2, openDialog(scope.row)">编辑</el-button>
						<el-button type="danger" size="mini" @click.stop="removeFn(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</Table>
		</div>
		<!-- 新增 -->
		<el-dialog :title="dialogType == 1 ? '新增' : '编辑'" :visible.sync="dialogVisible" :close-on-click-modal="false"
			v-el-drag-dialog width="50%">
			<el-form ref="form" label-width="120px">
				<el-form-item label="用户名">
					<el-input v-model="form.username" placeholder="请输入用户名,只能输入数字和字母"></el-input>
				</el-form-item>
				<el-form-item label="登录账号">
					<el-input v-model="form.username" placeholder="请输入登录账号" readonly="">
						<template slot="append">@{{userInfo.id}}</template>
					</el-input>
				</el-form-item>
				<el-form-item label="登录密码">
					<el-input v-model="form.password" v-if="dialogType == 1" placeholder="请输入登录密码"></el-input>
					<el-input v-model="form.password" v-else placeholder="请输入登录密码,留空为不修改"></el-input>
				</el-form-item>
				<el-form-item label="说明">
					<el-input v-model="form.remark" placeholder="请输入说明"></el-input>
				</el-form-item>
				<el-form-item label="移动端权限">
					<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
						@change="handleCheckAllChange">全选</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkAppMenus" @change="handleCheckedAppMenusChange">
						<el-checkbox v-for="item in AppMenus" :label="item.id"
							:key="item.id">{{item.name}}</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="菜单权限">
					<el-tree :data="menus" ref="authTree" show-checkbox node-key="id" :default-checked-keys="selected"
						@check="handleCheckChange">
					</el-tree>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click.stop="dialogVisible = false">取 消</el-button>
				<el-button type="warning" @click.stop="confirmFn">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				// 新增、编辑弹窗
				dialogType: 1, //1新增2编辑
				dialogVisible: false,
				form: {
					username: '',
					account: '',
					app_menus: ''
				},
				menus: [],
				selected: [],
				userInfo: {},
				AppMenus: [{
						id: "1",
						name: '基础模块'
					},
					{
						id: "2",
						name: '运营模块'
					},
					{
						id: "3",
						name: '仓库模块'
					},
					{
						id: "4",
						name: '财务模块'
					},
				],
				checkAll: false,
				isIndeterminate: true,
				checkAppMenus: []
			}
		},
		mounted() {
			this.getList()
			this.getMenus()
			this.userInfo = JSON.parse(sessionStorage.getItem('member'))
		},
		methods: {
			handleCheckAllChange(val) {
				console.log(val, "handleCheckAllChange")
				if (val) {
					this.checkAppMenus = [];
					this.AppMenus.forEach(item => {
						this.checkAppMenus.push(item.id)
					})
				} else {
					this.checkAppMenus = []
				}
				this.isIndeterminate = !val;
			},
			handleCheckedAppMenusChange(value) {
				console.log(value, "handleCheckedCitiesChange")
				this.checkAppMenus = value
				let checkedCount = value.length;
				this.checkAll = checkedCount === this.AppMenus.length;
				this.isIndeterminate = checkedCount > 0 && checkedCount < this.AppMenus.length;
			},
			openDialog(item) {
				if (item) {
					this.form = item
					this.selected = item.menus_arr
					this.dialogType = 2
					this.dialogVisible = true
					this.checkAppMenus = item.app_menus_arr
				} else {
					this.form = {}
					this.selected = []
					this.dialogType = 1
					this.dialogVisible = true
					this.checkAppMenus = []
					this.$refs.authTree.setCheckedKeys([])
				}

			},
			getMenus() {
				this.$http.post(`erp/v1/home/menus`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.menus = data
					}
				})
			},
			handleCheckChange(data, checked) {
				this.selected = checked.checkedKeys
			},
			getList() {
				this.$http.post(`erp/v1/users/lst`, {
					page: this.page,
					pageNum: this.pageNum,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200) {
						this.tableData = data.rows
						this.total = data.total
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 确定
			confirmFn() {
				if (!this.form.username) {
					this.$message.error('请输入登录用户名');
					return
				}
				if (!this.form.password && this.dialogType == 1) {
					this.$message.error('请输入登录密码');
					return
				}
				let url = this.dialogType == 1 ? '/erp/v1/users/add' : '/erp/v1/users/modify'
				this.form.account = this.form.username + "@" + this.userInfo.id
				this.form.menus = ""
				this.selected.forEach(i => {
					this.form.menus += i + ","
				})
				this.form.app_menus = ""
				this.checkAppMenus.forEach(i => {
					this.form.app_menus += i + ","
				})

				this.$http.post(`${url}`, this.form).then((res) => {
					const {
						code,
						msg
					} = res.data
					if (code == 200) {
						this.$message.success(msg);
						this.dialogVisible = false
						this.getList()
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 删除
			removeFn(item) {
				this.$confirm('此操作将删除该数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$http.post(`erp/v1/users/delete`, {
						id: item.id
					}).then((res) => {
						const {
							code,
							msg
						} = res.data
						if (code == 200) {
							this.$message.success(msg);
							this.getList()
						} else {
							this.$message.error(msg);
						}
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				});
			}
		},
	}
</script>

<style lang="scss" scoped></style>